@import "custom_variables";

// Functions for text-setting mixin
@function font-size($level) {
    @return map-get(map-get($text-settings, $level), 'font-size');
}

@function line-height($level) {
    @return map-get(map-get($text-settings, $level), 'line-height');
}

@function font-family($level) {
    @return map-get(map-get($text-settings, $level), 'font-family');
}

@function font-weight($level) {
    @return map-get(map-get($text-settings, $level), 'font-weight');
}

@function text-transform($level) {
    @return map-get(map-get($text-settings, $level), 'text-transform');
}

@function letter-spacing($level) {
    @return map-get(map-get($text-settings, $level), 'letter-spacing');
}

@mixin text-setting($level) {
    font-family: font-family($level);
    font-weight: font-weight($level);
    font-size: font-size($level);
    line-height: line-height($level);
    text-transform: text-transform($level);
    letter-spacing: letter-spacing($level);
}

//Define default header sizes
@mixin font-heading-h1-breakout {
    @include text-setting('font-heading-h1-breakout');
}

@mixin font-heading-h1 {
    @include text-setting('font-heading-h1');
}

@mixin font-heading-h2-breakout {
    @include text-setting('font-heading-h2-breakout');
}

@mixin font-heading-h2 {
    @include text-setting('font-heading-h2');
}

@mixin font-heading-h3-breakout {
    @include text-setting('font-heading-h3-breakout');
}

@mixin font-heading-h3 {
    @include text-setting('font-heading-h3');
}

@mixin font-heading-h4 {
    @include text-setting('font-heading-h4');
}

@mixin font-heading-h5 {
    @include text-setting('font-heading-h5');
}

@mixin font-heading-h6 {
    @include text-setting('font-heading-h6');
}

//Define default text sizes
@mixin font-text-l {
    @include text-setting('font-text-l');
}

@mixin font-text-m {
    @include text-setting('font-text-m');
}

@mixin font-text-s {
    @include text-setting('font-text-s');
}

@mixin font-text-xs {
    @include text-setting('font-text-xs');
}

@mixin font-quote {
    @include text-setting('font-quote');
}

@mixin custom-bullets($color) {
    list-style: none;
    margin-left: 0;

    li {
        position: relative;
        padding-left: 14px;
        @include font-text-m;

        &:before {
            content: '';
            background-color: $color;
            width: 6px;
            height: 6px;
            position: absolute;
            display: block;
            top: 10px;
            left: 0;

            @include breakpoint(medium) {
                top: 15px;
            }
        }
    }
}

@mixin custom-bullets-image($image) {
    list-style: none;
    margin-left: 0;

    li {
        position: relative;
        padding-left: 26px;
        margin-bottom: 4px;

        @include breakpoint(xlarge) {
            padding-left: 30px;
        }

        &:before {
            content: '';
            background-image: url("/static/default/media/images/themes/#{$theme-name}/svg/#{$image}");
            background-size: auto 100%;
            background-repeat: no-repeat;
            width: 18px;
            height: 18px;
            position: absolute;
            display: block;
            top: 5px;
            left: 0;

            @include breakpoint(xlarge) {
                width: 20px;
                height: 20px;
                top: 11px;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:2005";